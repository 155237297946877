
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import TopMessage from '@/components/organisms/dms/Online/TopMessage.vue'
import Usage from '@/components/organisms/dms/Online/Usage.vue'

export default defineComponent({
  name: 'Online',
  components: {
    TopMessage,
    Usage,
  },
  props: {
  },
  setup () {
    const router = useRouter()

    return {
      router,
    }
  },
})
