
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import DssIcon from '@/components/atoms/DssIcon.vue'
import AppButton from '@/components/atoms/AppButton.vue'
import TextLinkWithArrow from '@/components/molecules/TextLinkWithArrow.vue'

export default defineComponent({
  name: 'Usage',
  components: {
    DssIcon,
    AppButton,
    TextLinkWithArrow,
  },
  setup () {
    const router = useRouter()
    const baseUrl = process.env.VUE_APP_BASE_URL

    return {
      router,
      baseUrl,

      mailto (address: string, subject: string, body: string) {
        location.href = `mailto:${address}?subject=${subject}&body=${body}`
      },
    }
  },
})
