
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import Online from '@/components/organisms/dms/Online/Online.vue'
export default defineComponent({
  components: {
    Online,
  },
  setup () {
    const router = useRouter()
    return {
      router,
    }
  },
})
